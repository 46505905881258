<template>
  <router-view></router-view>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {}
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
</style>
